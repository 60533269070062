import { Theme } from 'utils/theme'
import { Color } from 'atomic'
import { BrandColor } from './colors'
import './assets/global.css'

export const theme: Theme = {
  typography: {
    primary: 'Signika, Roboto, Tahoma'
  },
  color: {
    primary: BrandColor.WeinmannSerdilPrimaryDark,
    primaryLight: BrandColor.WeinmannSerdilPrimaryLight,
    accessory: BrandColor.DarkBlue,
    tertiary: BrandColor.LightBlue,
    clientAccessory: BrandColor.DarkBlue,
    doctorAccessory: BrandColor.DarkGrey,
    warning: BrandColor.Yellow,
    success: BrandColor.Green,
    alert: BrandColor.Red,
    background: Color.White,
    footer: BrandColor.Gray
  }
}
