export enum BrandColor {
  Blue = '#018998',
  DarkBlue = '#005B6A',
  LightBlue = '#18B3B3',
  Gray = '#58595B',
  DarkGrey = '#302A38',
  Green = '#04AB77',
  Yellow = '#FFDA41',
  Red = '#E72F3F',
  WeinmannSerdil = '#018391',
  WeinmannSerdilAssessory = '#18B3B3',
  WeinmannSerdilPrimaryDark = '#047380',
  WeinmannSerdilPrimaryLight = '#E6F3F5'
}
